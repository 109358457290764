import axiosInstance from "../../api/index";

const initialState = {
  stores: [],
  storesUpdates: [],
  store: undefined,
  users: [],
  user: undefined,
  inventory: [],
  product: undefined,
  marketplaces: [],
  marketplace: undefined,
  updateStoreAndMarketplace: [],
  storeMarketplace: undefined,
  currentUser: undefined,
  roles: [],
  autoEvent: false,
};

export default {
  init,
  onCreateUser,
  getAllUsers,
  onDeleteUser,
  onSelectedUser,
  onUpdateUser,
  getAllStores,
  onDeleteStore,
  onActiveStore,
  onSelectedStore,
  getStoresBySAP,
  getStoreByID,
  getAllMarketplaces,
  onCreateMarketplace,
  onSelectedMarketplace,
  onDeleteMarketplace,
  onUpdateMarketplace,
  getInventoryUpdate,
  onStoreMarketplaceSelected,
  onCleanInventoryUpdate,
  handleStatusInventoryUpdate,
  onCreateStoreInMarketplace,
  onDeleteStoreByMarketplace,
  onUpdateStoreMarketplace,
  getProducts,
  onUpdateProduct,
  getProductsBySAP,
  onSelectedProduct,
  getUpdateByStoreAndMarketplace,
  saveUpdateByStoreAndMarketplace,
  sendInventoryToMarketplaceFromStore,
  clearState,
  onLogout,
  handleAutoEventStatus,
  getAutoEventStatus,
  getHistoryUpdates,
  onCreateConfiguration,
  getProductBySku,
};

function init() {
  return Promise.all([
    axiosInstance.get("store/all"),
    axiosInstance.get("marketplace"),
    axiosInstance.get("product/all"),
    axiosInstance.get("user/roles"),
    axiosInstance.get("user/all"),
    axiosInstance.get(`autoevent`),
  ]).then(([stores, marketplaces, inventory, roles, users, event]) => {
    console.log(inventory, "INIT");
    users.map((user) => {
      if (user.active) return (user.active = "Activo");
      else return (user.active = "Inactivo");
    });
    stores.map((store) => {
      if (store.active) return (store.active = "Activo");
      else return (store.active = "Inactivo");
    });
    marketplaces.map((marketplace) => {
      if (marketplace.active) return (marketplace.active = "Activo");
      else return (marketplace.active = "Inactivo");
    });
    return Object.assign(initialState, {
      stores,
      users,
      inventory,
      marketplaces,
      currentUser: JSON.parse(localStorage.getItem("user")),
      roles,
      autoEvent: event.active,
    });
  });
}

// User methods

async function onCreateUser(input) {
  return await axiosInstance.post("user/signIn", input.body);
}

async function getAllUsers(input) {
  const users = await axiosInstance.get("user/all");
  users.map((user) => {
    if (user.active) return (user.active = "Activo");
    else return (user.active = "Inactivo");
  });
  return Object.assign(input.state, { users });
}

async function onDeleteUser(input) {
  return await axiosInstance.delete(`user/${input.id}`);
}

async function onSelectedUser(input) {
  return await Object.assign(input.state, { user: input.user });
}

async function onUpdateUser(input) {
  return await axiosInstance.put(`user`, input.body);
}

//Stores methods

async function getAllStores(input) {
  const stores = await axiosInstance.get("store/all");
  stores.map((store) => {
    if (store.active) return (store.active = "Activo");
    else return (store.active = "Inactivo");
  });
  return Object.assign(input.state, { stores });
}

async function onDeleteStore(input) {
  return await axiosInstance.delete(`store/${input.id}`);
}

async function onSelectedStore(input) {
  return await Object.assign(input.state, { store: input.store });
}

async function getStoresBySAP() {
  await axiosInstance.get(`store/updated-stores`);
}

async function getStoreByID(input) {
  const store = await axiosInstance.get(`store/${input.id}/show`);
  return Object.assign(input.state, { store });
}

async function onActiveStore(input) {
  await axiosInstance.put(`store/activeStore`, {
    storeId: input.body,
  });
}

//Marketplace methods

async function getAllMarketplaces(input) {
  const marketplaces = await axiosInstance.get("marketplace");
  marketplaces.map((marketplace) => {
    if (marketplace.active) return (marketplace.active = "Activo");
    else return (marketplace.active = "Inactivo");
  });
  const marketplace = input.state.marketplace
    ? marketplaces?.find((mk) => mk.id === input.state.marketplace.id)
    : undefined;

  return Object.assign(input.state, { marketplaces, marketplace });
}

async function onCreateMarketplace(input) {
  return await axiosInstance.post(`marketplace`, input.body);
}

async function onSelectedMarketplace(input) {
  return await Object.assign(input.state, { marketplace: input.marketplace });
}

async function onDeleteMarketplace(input) {
  return await axiosInstance.delete(`marketplace/${input.id}`);
}

async function onUpdateMarketplace(input) {
  return await axiosInstance.put(`marketplace`, input.body);
}

async function getInventoryUpdate(input) {
  const storesUpdates = await axiosInstance.get(
    `inventoryUpdate/${input.id}/marketplace`
  );

  return Object.assign(input.state, { storesUpdates });
}

async function onCleanInventoryUpdate(input) {
  return Object.assign(input.state, { storesUpdates: [] });
}

async function handleStatusInventoryUpdate(input) {
  return await axiosInstance.post(`inventoryUpdate/desactive`, input.body);
}

async function onCreateStoreInMarketplace(input) {
  return await axiosInstance.post(`marketplace/store`, input.body);
}

async function onStoreMarketplaceSelected(input) {
  return Object.assign(input.state, {
    storeMarketplace: input.storeMarketplace,
  });
}

async function onUpdateStoreMarketplace(input) {
  return await axiosInstance.put(`marketplace/store`, input.body);
}

async function onDeleteStoreByMarketplace(input) {
  return await axiosInstance.delete(`marketplace/store/${input.id}`);
}

//Product methods

async function onSelectedProduct(input) {
  return false;
}

async function onUpdateProduct(input) {
  await axiosInstance.put(`product`, input.body);
}

async function getProducts(input) {
  const inventory = await axiosInstance.get(
    `product/all?page=${input.page}&search=${input.search ? input.search : ""}`
  );
  const product = input.state.product
    ? inventory?.rows.find((mk) => mk.id === input.state.product.id)
    : undefined;
  return Object.assign(input.state, { inventory, product });
}

async function getProductsBySAP() {
  return await axiosInstance.get(`product/updated-products`);
}

async function onLogout() {
  localStorage.clear();
}

async function clearState() {
  return await Object.assign(initialState);
}

//Update inventory methos
async function getUpdateByStoreAndMarketplace(input) {
  const updateStoreAndMarketplace = await axiosInstance.get(
    `inventoryUpdate/${input.storeId}/updateByMarketplace/${input.marketplaceId}`
  );

  return Object.assign(input.state, { updateStoreAndMarketplace });
}

async function saveUpdateByStoreAndMarketplace(input) {
  return await axiosInstance.post(`inventoryUpdate`, input.body);
}

async function sendInventoryToMarketplaceFromStore(input) {
  return await axiosInstance.post(`inventoryUpdate/send/byStore`, input.body);
}

async function getAutoEventStatus(input) {
  const autoEvent = await axiosInstance.get(`autoevent`);
  return Object.assign(input.state, { autoEvent: autoEvent.active });
}

async function handleAutoEventStatus() {
  return await axiosInstance.post(`autoevent`);
}

async function getHistoryUpdates(input) {
  return await axiosInstance.get(`eventUpdate?page=${input.page}}`);
}

async function onCreateConfiguration(input) {
  return await axiosInstance.post("configuration", input.updates);
}

function getProductBySku(sku) {
  return axiosInstance.get(`product/product/${sku}`);
}
