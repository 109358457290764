import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import Dashboard from "./dashboard";
import Inventory from "./inventory";
import MarketPlace from "./marketplaces";
import Stores from "./stores";
import Users from "./users";

import Sidenav from "../../components/sidenav";
import Loader from "../../components/loader";
import SnackbarAlert from "../../components/snackbarAlert";
import { TOKEN_KEY } from "../../config";

import service from "./service";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    height: "100vh",
    overflow: "auto",
    width: "100%",
  },
  container: {
    padding: "16px",
  },
}));

function Main(props) {
  const [state, setState] = useState();
  const [product, setProduct] = useState();
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [historyUpdates, setHistoryUpdates] = useState([]);
  const classes = useStyles();
  let content;
  console.log(state);
  switch (page) {
    case 0:
      content = <Dashboard state={state} setPage={setPage} />;
      break;
    case 1:
      content = (
        <Stores
          {...props}
          state={state}
          getAllStores={getAllStores}
          onDeleteStore={onDeleteStore}
          onSelectedStore={onSelectedStore}
          getStoresBySAP={getStoresBySAP}
          onActiveStore={onActiveStore}
          isLoading={isLoading}
        />
      );
      break;
    case 2:
      content = (
        <Inventory
          {...props}
          state={state}
          product={product}
          onSelectedProduct={onSelectedProduct}
          getProducts={getProducts}
          getProductsBySAP={getProductsBySAP}
          isLoading={isLoading}
          onUpdateProduct={onUpdateProduct}
          getHistoryUpdates={getHistoryUpdates}
          setHistoryUpdates={setHistoryUpdates}
          historyUpdates={historyUpdates}
        />
      );
      break;
    case 3:
      content = (
        <MarketPlace
          {...props}
          state={state}
          getAllMarketplaces={getAllMarketplaces}
          onCreateMarketplace={onCreateMarketplace}
          onSelectedMarketplace={onSelectedMarketplace}
          onDeleteMarketplace={onDeleteMarketplace}
          onUpdateMarketplace={onUpdateMarketplace}
          getInventoryUpdate={getInventoryUpdate}
          handleStatusInventoryUpdate={handleStatusInventoryUpdate}
          onCreateStoreInMarketplace={onCreateStoreInMarketplace}
          onStoreMarketplaceSelected={onStoreMarketplaceSelected}
          onUpdateStoreMarketplace={onUpdateStoreMarketplace}
          getUpdateByStoreAndMarketplace={getUpdateByStoreAndMarketplace}
          getStoreByID={getStoreByID}
          saveUpdateByStoreAndMarketplace={saveUpdateByStoreAndMarketplace}
          sendInventoryToMarketplaceFromStore={
            sendInventoryToMarketplaceFromStore
          }
          onSelectedStore={onSelectedStore}
          onDeleteStoreByMarketplace={onDeleteStoreByMarketplace}
          isLoading={isLoading}
          onCreateConfiguration={onCreateConfiguration}
        />
      );
      break;
    case 4:
      content = (
        <Users
          {...props}
          state={state}
          onCreateUser={onCreateUser}
          getAllUsers={getAllUsers}
          onSelectedUser={onSelectedUser}
          onDeleteUser={onDeleteUser}
          onUpdateUser={onUpdateUser}
          isLoading={isLoading}
        />
      );
      break;
    default:
      setPage(0);
      break;
  }

  useEffect(() => {
    if (!localStorage.getItem(TOKEN_KEY)) props.history.replace("/auth/signin");
    init();
  }, [props.history]);

  return state ? (
    <div className={classes.root}>
      <Sidenav
        {...props}
        onLogout={onLogout}
        isLoading={isLoading}
        state={state}
        handleAutoEventStatus={handleAutoEventStatus}
        page={page}
        setPage={setPage}
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {state ? (
          <div className={classes.container}>{content}</div>
        ) : (
          <Loader />
        )}
      </main>
      <SnackbarAlert
        error={error}
        open={open}
        setOpen={setOpen}
        message={message}
      />
    </div>
  ) : (
    <Loader />
  );

  function init() {
    return service.init().then(setState);
  }

  function onLogout() {
    return service.onLogout().then(props.history.replace("/auth/signin"));
  }

  function onCreateUser(body) {
    setIsLoading(true);
    return service
      .onCreateUser({ body })
      .then((res) => {
        setOpen(true);
        setError(false);
        setMessage("Usuario creado correectamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function getAllUsers() {
    setIsLoading(true);
    return service
      .getAllUsers({ state })
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }

  function onSelectedUser(user) {
    return service.onSelectedUser({ state, user });
  }

  function onDeleteUser(id) {
    setIsLoading(true);
    return service
      .onDeleteUser({ id })
      .then(() => {
        setOpen(true);
        setError(false);
        setMessage("Usuario eliminado correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function onUpdateUser(body) {
    setIsLoading(true);
    return service
      .onUpdateUser({ body })
      .then(() => {
        setOpen(true);
        setError(false);
        setMessage("Usuario editado correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function getAllStores() {
    setIsLoading(true);
    return service
      .getAllStores({ state })
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }

  function getStoreByID(id) {
    setIsLoading(true);
    return service
      .getStoreByID({ state, id })
      .then((data) => {
        setState({ ...state, data });
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  function onDeleteStore(id) {
    setIsLoading(true);
    return service
      .onDeleteStore({ id })
      .then(() => {
        setOpen(true);
        setError(false);
        setMessage("Tienda inactivada correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function onActiveStore(body) {
    setIsLoading(true);
    return service
      .onActiveStore({ body })
      .then(() => {
        setOpen(true);
        setError(false);
        setMessage("Tienda activada correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function onSelectedStore(store) {
    return service.onSelectedStore({ state, store });
  }

  function getStoresBySAP() {
    setIsLoading(true);
    return service
      .getStoresBySAP()
      .then(() => {
        setOpen(true);
        setError(false);
        setMessage("Tiendas actualizadas correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function getAllMarketplaces() {
    setIsLoading(true);
    return service
      .getAllMarketplaces({ state })
      .then((res) => {
        setIsLoading(false);
        setState({ ...state, res });
      })
      .catch(() => setIsLoading(false));
  }

  function onCreateMarketplace(body) {
    setIsLoading(true);
    return service
      .onCreateMarketplace({ body })
      .then(() => {
        setOpen(true);
        setError(false);
        setMessage("Marketplace creado correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function onCreateStoreInMarketplace(body) {
    setIsLoading(true);
    return service
      .onCreateStoreInMarketplace({ body })
      .then(() => {
        setOpen(true);
        setError(false);
        setMessage("Tienda creada correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function onDeleteMarketplace(id) {
    setIsLoading(true);
    return service
      .onDeleteMarketplace({ id })
      .then(() => {
        setOpen(true);
        setError(false);
        setMessage("Marketplace eliminado correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function onUpdateMarketplace(body) {
    setIsLoading(true);
    return service
      .onUpdateMarketplace({ body })
      .then(() => {
        setOpen(true);
        setError(false);
        setMessage("Marketplace actualizado correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function getInventoryUpdate(id) {
    setIsLoading(true);
    return service
      .getInventoryUpdate({ state, id })
      .then((data) => {
        setState({ ...state, data });
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  function handleStatusInventoryUpdate(body) {
    setIsLoading(true);
    return service
      .handleStatusInventoryUpdate({ body })
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }

  function onSelectedMarketplace(marketplace) {
    return service.onSelectedMarketplace({ state, marketplace }).then(setState);
  }

  function onStoreMarketplaceSelected(storeMarketplace) {
    setIsLoading(true);
    return service
      .onStoreMarketplaceSelected({ state, storeMarketplace })
      .then((res) => {
        setState({ ...state, res });
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  function onUpdateStoreMarketplace(body) {
    setIsLoading(true);
    return service
      .onUpdateStoreMarketplace({ body })
      .then(() => {
        setOpen(true);
        setError(false);
        setMessage("Tienda actualizada correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  async function onSelectedProduct(product) {
    if (product) {
      const currentProduct = await service.getProductBySku(product.sku);
      console.log(currentProduct);
      setProduct(currentProduct);
    } else {
      setProduct(undefined);
    }
  }

  function getProducts(page, search) {
    setIsLoading(true);
    return service
      .getProducts({ state, page, search })
      .then((res) => {
        setState(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  function getProductsBySAP() {
    setIsLoading(true);
    return service
      .getProductsBySAP()
      .then((res) => {
        console.log("[RESPUESTA] => ", res);
        setOpen(true);
        setError(false);
        setMessage("Productos actualizados correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function getUpdateByStoreAndMarketplace(storeId, marketplaceId) {
    setIsLoading(true);

    return service
      .getUpdateByStoreAndMarketplace({ state, storeId, marketplaceId })
      .then((res) => {
        setState({ ...state, res });
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  function saveUpdateByStoreAndMarketplace(body) {
    setIsLoading(true);

    return service
      .saveUpdateByStoreAndMarketplace({ body })
      .then(() => {
        setOpen(true);
        setError(false);
        setMessage("Configuración realizada correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function sendInventoryToMarketplaceFromStore(body) {
    setIsLoading(true);

    return service
      .sendInventoryToMarketplaceFromStore({ body })
      .then((res) => {
        console.log(res);
        setOpen(true);
        setError(false);
        setMessage("Inventario actualizado correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function onDeleteStoreByMarketplace(id) {
    setIsLoading(true);

    return service
      .onDeleteStoreByMarketplace({ id })
      .then((res) => {
        setOpen(true);
        setError(false);
        setMessage("Tienda eliminada correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function onUpdateProduct(body) {
    return service
      .onUpdateProduct({ body })
      .then((res) => {
        setOpen(true);
        setError(false);
        setMessage("Producto actualizado correctamente");
        setIsLoading(false);
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function handleAutoEventStatus() {
    service
      .handleAutoEventStatus()
      .then(async (res) => {
        setOpen(true);
        setError(false);
        setMessage("Cambio realizado correctamente");
        setIsLoading(false);
        await getAutoEventStatus();
      })
      .catch((res) => {
        setOpen(true);
        setError(true);
        setMessage(res);
        setIsLoading(false);
      });
  }

  function getAutoEventStatus() {
    return service
      .getAutoEventStatus({ state })
      .then((res) => setState({ ...state, res }));
  }

  function getHistoryUpdates(page) {
    setIsLoading(true);
    return service
      .getHistoryUpdates({ page })
      .then((res) => {
        setHistoryUpdates(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  function onCreateConfiguration(updates) {
    setIsLoading(true);
    return service
      .onCreateConfiguration({ updates })
      .then((res) => {
        setOpen(true);
        setError(false);
        setMessage("Configuración realizada correctamente");

        setIsLoading(false);
      })
      .catch((err) => {
        setOpen(true);
        setError(true);
        setMessage(err);
        setIsLoading(false);
      });
  }
}

export default Main;
