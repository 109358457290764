import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// material
import { Stack, TextField, MenuItem, Button } from "@material-ui/core";

// ----------------------------------------------------------------------

CreateMarketplaceStoreForm.propTypes = {
  handleOnSubmit: PropTypes.func,
};

export default function CreateMarketplaceStoreForm(props) {
  const CreateMarketplaceStoreSchema = Yup.object().shape({
    code: Yup.string().required(`El código es requerido`),
    storeId: Yup.string().required(`La tienda es requerida`),
    extraCode: Yup.string(),
    secretKey: Yup.string(),
    enviroment: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      code: ``,
      storeId: ``,
      extraCode: ``,
      secretKey: ``,
      enviroment: ``,
    },
    validationSchema: CreateMarketplaceStoreSchema,
    onSubmit: ({ code, storeId, extraCode, secretKey, enviroment }) => {
      props.onCreateStoreInMarketplace({
        code,
        storeId,
        extraCode,
        secretKey,
        enviroment,
        marketplaceId: props.marketplace.id,
      });
      props.setIsOpenCreateDialog(false);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} pt={2}>
          <TextField
            fullWidth
            type="text"
            label="Código"
            {...getFieldProps(`code`)}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />

          <TextField
            id="outlined-select-store"
            select
            {...getFieldProps(`storeId`)}
            error={Boolean(touched.storeId && errors.storeId)}
            helperText={touched.storeId && errors.storeId}
            fullWidth
            label="Tienda"
            // onChange={({ target }) => handleChangeInputStore(key, target.value)}
          >
            {props.stores.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            type="text"
            label="Código extra"
            {...getFieldProps(`extraCode`)}
            error={Boolean(touched.extraCode && errors.extraCode)}
            helperText={touched.extraCode && errors.extraCode}
          />
          <TextField
            fullWidth
            type="text"
            label="Llave secreta"
            {...getFieldProps(`secretKey`)}
            error={Boolean(touched.secretKey && errors.secretKey)}
            helperText={touched.secretKey && errors.secretKey}
          />

          <TextField
            fullWidth
            type="text"
            label="Código VTEX"
            {...getFieldProps(`enviroment`)}
            error={Boolean(touched.enviroment && errors.enviroment)}
            helperText={touched.enviroment && errors.enviroment}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button fullWidth size="large" type="submit" variant="contained">
            Crear
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
