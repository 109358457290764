import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  Table,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from "@material-ui/core";

import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import AddIcon from "@material-ui/icons/Add";

import MoreMenuExtend from "./moreMenu";
import CreateMarketplaceStoreForm from "./createMarketplaceStoreForm";
import UpdateSotreMarketplaceForm from "./updateStoreMarketplaceForm";
import UpdateInventory from "./updateInventory";
import UploadCSV from "./csvUpload";
import Loader from "./loader";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "12px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  refreshBtn: {
    marginLeft: "auto !important",
    marginRight: "12px",
  },
  rows: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    width: "50px",
    background: "#fffff",
    borderRadius: "20px",
    color: "#00AB55",
    border: "solid 1px #00AB55",
    fontWeight: "600",
    fontSize: "12px",
    padding: "5px",
  },
  textInactive: {
    width: "50px",
    background: "#fffff",
    borderRadius: "20px",
    color: "red",
    border: "solid 1px red",
    fontWeight: "600",
    fontSize: "12px",
    padding: "5px",
  },
}));

function MarketplaceStore(props) {
  const classes = useStyles();
  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);
  const [isOpenUpdateDialog, setIsOpenUpdateDialog] = useState(false);
  const [isOpenCSVDialog, setOpenCSVDialog] = useState(false);
  const [isAttachement, setIsAttachment] = useState();
  const [isLoading, setIsLoading] = useState(false);
  console.log(props);
  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Volver">
            <IconButton
              color="primary"
              onClick={() => {
                if (!props.isUpdateInventory) {
                  props.setIsAddStore(false);
                  props.onSelectedMarketplace(undefined);
                } else {
                  props.setIsUpdateInventory(false);
                  props.onStoreMarketplaceSelected(undefined);
                }
              }}
            >
              <ArrowBackIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Typography variant="h5">{`${props.marketplace?.name} - ${
            props.isUpdateInventory ? "Configuración" : "Tiendas"
          }`}</Typography>
        </div>
        {!props.isUpdateInventory && (
          <div className={classes.refreshBtn}>
            <Button
              onClick={() => {
                setIsOpenCreateDialog(true);
              }}
              variant="contained"
              startIcon={<AddIcon />}
            >
              Agregar Tienda
            </Button>
          </div>
        )}
      </div>
      {!props.isLoading ? (
        !props.isUpdateInventory ? (
          <TableMarketplaceStore
            data={props.marketplace?.marketplaceStore}
            marketplace={props.marketplace}
            storesUpdates={props.storesUpdates}
            getInventoryUpdate={props.getInventoryUpdate}
            handleStatusInventoryUpdate={props.handleStatusInventoryUpdate}
            onStoreMarketplaceSelected={props.onStoreMarketplaceSelected}
            getStoreByID={props.getStoreByID}
            setIsOpenUpdateDialog={setIsOpenUpdateDialog}
            setIsUpdateInventory={props.setIsUpdateInventory}
            getStoreByID={props.getStoreByID}
            getUpdateByStoreAndMarketplace={
              props.getUpdateByStoreAndMarketplace
            }
            sendInventoryToMarketplaceFromStore={
              props.sendInventoryToMarketplaceFromStore
            }
            setOpenCSVDialog={setOpenCSVDialog}
            onDeleteStoreByMarketplace={props.onDeleteStoreByMarketplace}
          />
        ) : (
          <UpdateInventory
            storeMarketplace={props.storeMarketplace}
            marketplace={props.marketplace}
            store={props.store}
            updateStoreAndMarketplace={props.updateStoreAndMarketplace}
            saveUpdateByStoreAndMarketplace={
              props.saveUpdateByStoreAndMarketplace
            }
            setIsUpdateInventory={props.setIsUpdateInventory}
            isUpdateInventory={props.isUpdateInventory}
          />
        )
      ) : (
        <Loader />
      )}

      {/* Create dialog */}
      <Dialog
        fullWidth
        scroll="paper"
        maxWidth="sm"
        open={isOpenCreateDialog}
        onClose={() => setIsOpenCreateDialog(false)}
        aria-labelledby="dialog-create-marketplace-store"
        aria-describedby="dialog-create-marketplace-description-store"
      >
        <DialogTitle id="dialog-create-marketplace-store">{`Registrar tienda`}</DialogTitle>
        <DialogContent>
          <CreateMarketplaceStoreForm
            onCreateStoreInMarketplace={props.onCreateStoreInMarketplace}
            stores={props.stores}
            marketplace={props.marketplace}
            setIsOpenCreateDialog={setIsOpenCreateDialog}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenCreateDialog(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      {/* Update Dialog */}
      {props.storeMarketplace && (
        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="sm"
          open={isOpenUpdateDialog}
          onClose={() => setIsOpenUpdateDialog(false)}
          aria-labelledby="dialog-create-marketplace-store"
          aria-describedby="dialog-create-marketplace-description-store"
        >
          <DialogTitle id="dialog-create-marketplace-store">{`Editar tienda`}</DialogTitle>
          <DialogContent>
            <UpdateSotreMarketplaceForm
              stores={props.stores}
              store={props.storeMarketplace}
              marketplace={props.marketplace}
              setIsOpenUpdateDialog={setIsOpenUpdateDialog}
              onUpdateStoreMarketplace={props.onUpdateStoreMarketplace}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIsOpenUpdateDialog(false);
                props.onStoreMarketplaceSelected(undefined);
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* Load CSV from config */}
      {props.store && (
        <Dialog
          fullWidth
          scroll="paper"
          maxWidth="sm"
          open={isOpenCSVDialog}
          onClose={async () => {
            await props.onSelectedStore(undefined);
            setOpenCSVDialog(false);
          }}
          aria-labelledby="dialog-edit-marketplace-load-csv"
          aria-describedby="dialog-edit-marketplace-description-load-csv"
        >
          <DialogTitle id="dialog-edit-marketplace-load-csv">{`Cargar CSV de configuración`}</DialogTitle>
          <DialogContent>
            {props.store?.Products?.length > 0 ? (
              <UploadCSV
                onUpload={handleProcessAttachFileToUpload}
                onSubmit={handleOnSubmitAttachFile}
                isLoading={isLoading}
              />
            ) : (
              <Stack display="flex" justifyContent="center" p={3} width="100%">
                <Typography variant="body1" textAlign="center">
                  Esta tienda no tiene productos asociados, por favor actualice
                  el inventario para continuar
                </Typography>
              </Stack>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                await props.onSelectedStore(undefined);
                setOpenCSVDialog(false);
              }}
              disabled={props.isLoading}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );

  function handleProcessAttachFileToUpload(data) {
    if (data.length) {
      const payload = data.reduce((acc, el) => {
        const findProduct = props.store.Products.find(
          ({ sku }) => Number(sku).toString() === el.sku
        );

        if (findProduct)
          return [
            ...acc,
            {
              ...el,
              marketplaceId: Number(props.marketplace.id),
              storeId: props.store.id,
              productId: findProduct.id,
            },
          ];
        return acc;
      }, []);

      setIsAttachment(payload);
    }
  }

  async function handleOnSubmitAttachFile() {
    setIsLoading(true);
    try {
      const payload = {
        storeId: Number(props.store.id),
        marketplaceId: Number(props.marketplace.id),
        updates: isAttachement,
      };
      setOpenCSVDialog(false);

      await props.saveUpdateByStoreAndMarketplace(payload);
      await props.onSelectedStore(undefined);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error save update config by store and marketplace`, error);
    }
    setIsLoading(false);
  }
}

function TableMarketplaceStore({
  data,
  storesUpdates,
  getInventoryUpdate,
  marketplace,
  handleStatusInventoryUpdate,
  onStoreMarketplaceSelected,
  setIsOpenUpdateDialog,
  setIsUpdateInventory,
  getStoreByID,
  getUpdateByStoreAndMarketplace,
  sendInventoryToMarketplaceFromStore,
  setOpenCSVDialog,
  onDeleteStoreByMarketplace,
}) {
  const classes = useStyles();

  return (
    <Paper elevation={5}>
      <TableContainer style={{ marginTop: "32px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Código</TableCell>
              <TableCell align="center">Tienda</TableCell>
              <TableCell align="center">Código extra</TableCell>
              <TableCell align="center">Actualizar</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => {
              const storeWithUpdate = storesUpdates.find(
                (store) => store.storeId === row.store.id
              );
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {row.code}
                  </TableCell>
                  <TableCell align="center">{row.store.description}</TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.extraCode}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <Button
                      variant="outlined"
                      // disabled={
                      //   storeWithUpdate !== undefined
                      //     ? !storeWithUpdate?.active
                      //     : true
                      // }
                      onClick={() =>
                        sendInventoryToMarketplaceFromStore({
                          storeId: row.store.id,
                          marketplaceId: marketplace.id,
                        })
                      }
                    >
                      Actualizar inventario
                    </Button>
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <Typography
                      variant="p"
                      className={
                        row.store.active ? classes.text : classes.textInactive
                      }
                    >
                      {row.store.active ? "Activo" : "Inactivo"}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <MoreMenuExtend
                      statusConfig={storeWithUpdate?.active ?? false}
                      onDesactive={async () => {
                        const findStore = storesUpdates.find(
                          ({ storeId }) => storeId === row.store.id
                        );
                        if (findStore) {
                          await handleStatusInventoryUpdate({
                            storeId: row.store.id,
                            marketplaceId: marketplace.id,
                            active: !findStore.active,
                          });
                          await getInventoryUpdate(marketplace?.id);
                        } else {
                          return false;
                        }
                      }}
                      onEdit={() => {
                        onStoreMarketplaceSelected(row);
                        setIsOpenUpdateDialog(true);
                      }}
                      onConfig={async () => {
                        onStoreMarketplaceSelected(row);
                        setIsUpdateInventory(true);
                        await getStoreByID(row.store.id);
                        await getUpdateByStoreAndMarketplace(
                          row.store.id,
                          marketplace?.id
                        );
                      }}
                      onLoadFile={async () => {
                        await getStoreByID(row.store.id);
                        setOpenCSVDialog(true);
                      }}
                      onDelete={async () => {
                        await onDeleteStoreByMarketplace(row.id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default MarketplaceStore;
