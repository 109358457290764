import { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import DateObject from "react-date-object";
import { CSVLink, CSVDownload } from "react-csv";
import {
  Card,
  Stack,
  Typography,
  TextField,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@material-ui/core";
import {
  Store as StoreIcon,
  Download as DownloadIcon,
} from "@material-ui/icons";
import { DataGrid, esES } from "@mui/x-data-grid";
import SearchIcon from "@material-ui/icons/Search";

import Loader from "./loader";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "32px",
  },
  container: {
    display: "flex",
  },
  containerInfo: {
    display: "flex",
    alignItems: "center",
    marginLeft: "32px",
  },
  search: {
    marginTop: "32px",
    marginLeft: "32px",
    marginBottom: "12px",
  },
}));

export default function UpdateInventory(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    openDialogDelete: false,
    marketplace: {},
    products: undefined,
    storeName: ``,
    storeSku: ``,
    openDialogEdit: false,
    isLoading: false,
    editRowsModel: {},
    selectedIds: [],
    oldIds: [],
  });
  const [search, setSearch] = useState("");
  const [dataForDownload, setDataForDownload] = useState([]);
  const { products, marketplace, isLoading, editRowsModel, selectedIds } =
    state;
  const getDataMarketplace = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        marketplace: props.marketplace,
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error get marketplace by id`, error);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };
  const getDataByStore = () => {
    try {
      const ids = props.updateStoreAndMarketplace.map(
        ({ productId }) => productId
      );
      console.log(props.store);
      const mapProduct = props.store?.Products.map(
        ({
          id,
          description,
          sku,
          ProductStores: {
            internationalPrice,
            stock,
            internationalTax,
            basePrice,
            tax,
          },
        }) => {
          const findOldUpdate = props.updateStoreAndMarketplace.find(
            ({ productId }) => productId === id
          );

          return {
            id,
            sku: Number(sku),
            description,
            basePrice: internationalPrice.toFixed(2) ?? 0,
            stock,
            byPercentage: findOldUpdate ? findOldUpdate.byPercentage : true,
            qty: findOldUpdate ? findOldUpdate.qty : 100,
            minQty: findOldUpdate ? findOldUpdate.minQty : 0,
            notificationQty: findOldUpdate ? findOldUpdate.notificationQty : 0,
            internationalTax,
            basePriceBs: basePrice,
            tax,
          };
        }
      );
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        storeName: props.store?.description,
        storeSku: props.store?.sku,
        products: mapProduct,
        selectedIds: ids,
      }));
    } catch (err) {
      console.log(`Error get marketplace by id`, err);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };
  const handleChangeInputUpdateBy = (id, value) => {
    setState((prevState) => {
      const { products } = prevState;
      const newProducts = [...products];
      const findProduct = products.find((product) => product.id === id);
      findProduct.byPercentage = value === `percentage`;
      return { ...prevState, products: newProducts };
    });
  };

  const handleOnSelectedProducts = (ids) => {
    setState((prevState) => ({ ...prevState, selectedIds: ids }));
  };

  const handleDownloadConfig = async () => {
    console.log(products);
    const productsFromUpdate = products
      .filter(({ id }) => selectedIds.includes(id))
      .map(
        ({
          sku,
          description,
          byPercentage,
          qty,
          stock,
          basePrice,
          basePriceBs,
          internationalTax,
          tax,
        }) => ({
          storeId: props.store.sku,
          description,
          stock,
          internationPrice: parseFloat(basePrice),
          nationalPrice: parseFloat(basePriceBs),
          internationTax: parseFloat(internationalTax),
          nationalTax: parseFloat(tax),
          marketplace: props.marketplace.name,
          sku,
          byPercentage,
          qty,
        })
      );

    setDataForDownload(productsFromUpdate);
  };

  const handleSaveConfiguration = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const productsFromUpdate = products
        .filter(({ id }) => selectedIds.includes(id))
        .map(({ id, byPercentage, qty, minQty, notificationQty }) => ({
          storeId: Number(props.store.id),
          marketplaceId: Number(props.marketplace.id),
          productId: id,
          byPercentage,
          qty,
          minQty,
          notificationQty,
        }));

      const payload = {
        storeId: Number(props.store.id),
        marketplaceId: Number(props.marketplace.id),
        updates: productsFromUpdate,
      };
      const size = new TextEncoder().encode(JSON.stringify(payload)).length;
      const kiloBytes = size / 1024;
      const megaBytes = kiloBytes / 1024;
      console.log(megaBytes);
      await props.saveUpdateByStoreAndMarketplace(payload);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error save update config by store and marketplace`, error);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const handleEditRowsModelChange = useCallback((model) => {
    setState((prevState) => ({ ...prevState, editRowsModel: model }));
  }, []);

  const handleCellEditCommit = useCallback(
    (data) => {
      const { field, value, id } = data;
      setState((prevState) => {
        const { products } = prevState;
        const newProducts = [...products];
        const findProduct = newProducts.find((product) => product.id === id);
        findProduct[field] = value;

        return { ...prevState, products: newProducts };
      });
    },
    [products]
  );
  useEffect(async () => {
    await getDataMarketplace();
    await getDataByStore();
    return () => {
      setState({
        openDialogDelete: false,
        marketplace: {},
        products: undefined,
        storeName: ``,
        storeSku: ``,
        openDialogEdit: false,
        isLoading: false,
        editRowsModel: {},
        selectedIds: [],
        oldIds: [],
      });
    };
  }, [props.store]);

  const columns = [
    { field: `sku`, headerName: `SKU`, minWidth: 100 },
    {
      field: `description`,
      headerName: `Descripción`,
      minWidth: 250,
    },
    {
      field: `basePrice`,
      headerName: `Precio base ($)`,
      minWidth: 170,
    },
    {
      field: `stock`,
      headerName: `Stock`,
      type: `number`,
    },
    {
      field: `byPercentage`,
      headerName: `Actualización`,
      sortable: false,
      minWidth: 140,
      renderCell: ({ value, id }) => {
        return (
          <TextField
            id="outlined-select"
            key={id}
            select
            fullWidth
            size="small"
            value={products[id]?.byPercentage ? `percentage` : `qty`}
            defaultValue={value ? `percentage` : `qty`}
            onChange={({ target }) =>
              handleChangeInputUpdateBy(id, target.value)
            }
          >
            <MenuItem value="percentage">Porcentaje</MenuItem>
            {/* <MenuItem value="qty">Cantidad</MenuItem> */}
          </TextField>
        );
      },
    },
    {
      field: `qty`,
      headerName: `Cantidad`,
      type: `number`,
      sortable: false,
      editable: true,
    },
    {
      field: `minQty`,
      headerName: `Min. Cantidad`,
      type: `number`,
      sortable: false,
      editable: true,
    },
    {
      field: `notificationQty`,
      headerName: `Notificación`,
      type: `number`,
      sortable: false,
      editable: true,
    },
  ];
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.containerInfo}>
          <StoreIcon />
          <Typography
            variant="body1"
            sx={{ fontWeight: 600 }}
            mx={1}
          >{`Tienda: ${props.storeMarketplace?.store.description}`}</Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {props.store?.sku}
          </Typography>
        </div>
        <div style={{ marginLeft: "auto", marginRight: "32px" }}>
          <Tooltip title="Descargar configuración">
            <IconButton style={{ marginRight: "16px" }} color="primary">
              <CSVLink
                filename={`Configuración: ${
                  props.marketplace.name
                } ${new DateObject(new Date()).format(
                  "YYYY/MM/DD hh:mm:ss a"
                )}.csv`}
                data={dataForDownload}
                onClick={(event, done) => {
                  handleDownloadConfig().then(done);
                }}
              >
                <DownloadIcon color="primary" />
              </CSVLink>
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            onClick={async () => {
              await handleSaveConfiguration();
              props.setIsUpdateInventory(false);
            }}
          >
            Guardar configuración
          </Button>
        </div>
      </div>
      <TextField
        id="input-with-icon-textfield"
        className={classes.search}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="standard"
        placeholder="Buscar..."
      />
      {products !== undefined ? (
        products.length > 0 ? (
          <Stack width="100%" style={{ margin: "auto", marginTop: "24px" }}>
            <DataGrid
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              onCellEditCommit={handleCellEditCommit}
              editRowsModel={editRowsModel}
              onEditRowsModelChange={handleEditRowsModelChange}
              onSelectionModelChange={handleOnSelectedProducts}
              selectionModel={selectedIds}
              autoHeight
              loading={isLoading}
              rows={products.filter(
                (item) =>
                  item.sku
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item.description
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )}
              columns={columns}
              pageSize={10}
              checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              disableMultipleColumnsFiltering
            />
          </Stack>
        ) : (
          <Card style={{ marginTop: "24px" }}>
            <Stack display="flex" justifyContent="center" p={3} width="100%">
              <Typography variant="body1" textAlign="center">
                Esta tienda no tiene productos asociados, por favor actualice el
                inventario para continuar
              </Typography>
            </Stack>
          </Card>
        )
      ) : (
        <Loader />
      )}
    </div>
  );
}
