import { useState } from "react";
import clsx from "clsx";

import { makeStyles, propsToClassKey } from "@material-ui/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

import {
  Dashboard as DashboardIcon,
  Group as GroupIcon,
  Inventory as InventoryIcon,
  Store as StoreIcon,
  LocalGroceryStore as LocalGroceryStoreIcon,
} from "@material-ui/icons";

import SidenavItems from "./sidenavItems";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    padding: 16, // keep right padding when drawer closed,
    backgroundColor: "white",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - ${240}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    marginLeft: "auto",
    marginRight: "8px",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(0),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  logo: {
    width: "75px",
    marginRight: "16px",
  },
  profileAvatar: {
    margin: "auto",
  },
  profile: {
    alignContent: "left",
    width: "70px",
    height: "70px",
  },
  avatar: {
    width: "50px",
    height: "50px",
    margin: "auto",
    background: "#007B55",
  },
}));

function Sidenav(props) {
  const classes = useStyles();
  const items = [
    {
      id: 0,
      title: `Dashboard`,
      icon: <DashboardIcon />,
    },
    {
      id: 1,
      title: `Tiendas`,
      icon: <StoreIcon />,
    },
    {
      id: 2,
      title: `Productos`,
      icon: <InventoryIcon />,
    },
    {
      id: 3,
      title: `Marketplace`,
      icon: <LocalGroceryStoreIcon />,
    },
    {
      id: 4,
      title: `Usuarios`,
      icon: <GroupIcon />,
    },
  ];
  const [open, setOpen] = useState(true);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
        style={{ background: "transparent", boxShadow: "none" }}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon color="primary" />
          </IconButton>
          <img src="/static/logo.png" className={classes.logo} alt="logo"></img>
          <Switch
            checked={props.state.autoEvent}
            onChange={() => props.handleAutoEventStatus()}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Typography
            variant={props.state.autoEvent ? "h6" : "p"}
            sx={{
              color: props.state.autoEvent ? `text.primary` : `text.secondary`,
            }}
          >
            {`Proceso automatico: ${
              props.state.autoEvent ? "activo" : "inactivo"
            }`}
          </Typography>

          <Typography
            component="h1"
            variant="h6"
            sx={{ color: `text.secondary` }}
            className={classes.title}
          >
            {`${currentUser && currentUser.firstName} ${
              currentUser && currentUser.lastName
            }`}
          </Typography>
          <Tooltip title="cerrar sesión">
            <IconButton color="primary" onClick={() => props.onLogout()}>
              <ExitToAppIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <Avatar alt="Remy Sharp" className={classes.avatar}>
            {`${currentUser && currentUser.firstName.split("")[0]}${
              currentUser && currentUser.lastName.split("")[0]
            }`}
          </Avatar>
          <IconButton onClick={() => setOpen(false)} color="primary">
            <ChevronLeftIcon color="primary" />
          </IconButton>
        </div>
        <Divider />
        <List style={{ marginTop: "12px", marginBottom: "12px" }}>
          <SidenavItems
            items={items}
            page={props.page}
            setPage={props.setPage}
            {...props}
          />
        </List>
        <Divider />
      </Drawer>
    </>
  );
}

export default Sidenav;
